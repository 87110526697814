import { postRequest, getRequest } from './index'
import { SCMAPI } from '@/config'

// 集采项目分页
export const getCollectionList = (data, successCallback, failureCallback) => {
  postRequest(`${SCMAPI}/v2/centralizedPurchase/project/pageProjectByProjectStatus`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 获取集采项目详情
export const getCollectionDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SCMAPI}/v2/centralizedPurchase/project/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 报名集采项目认购
export const enterProjectSubscribe = (data, successCallback, failureCallback) => {
  postRequest(`${SCMAPI}/v2/centralizedPurchase/projectSubscribe/enterProjectSubscribe`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
export const checkEnterProjectSubscribe = (data, successCallback, failureCallback) => {
  postRequest(`${SCMAPI}/v2/centralizedPurchase/projectSubscribe/checkEnterProjectSubscribe`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

